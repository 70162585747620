<template>
  <div class="article-page space-between">
    <div class="left-wrapper">
      <div class="header dividing-line">
        <div class="title text-ellipsis-2">{{ title }}</div>
        <div class="info-wrapper space-between">
          <div class="article-info space-between">
            <div class="info-item">发布时间：{{ pubTime }}</div>
            <div class="info-item">浏览次数：{{ pv }}</div>
          </div>
          <div class="icon-wrapper">
            <el-popover placement="bottom" trigger="hover" popper-class="wechat-qr-popover">
              <div class="wechat-qr-wrapper">
                <img class="wechat-qr" :src="wechatShareQr" alt="">
                <div class="fs12 wechat-qr-info">打开微信“扫一扫”</div>
                <div class="fs12 wechat-qr-info">点击右上角进行分享</div>
              </div>
              <i class="iconfont iconicon_share_weixin" slot="reference"></i>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="content fs16" v-html="content"></div>
    </div>
    <div class="right-wrapper">
      <div class="header fs16 dividing-line vertical-center">相关内容</div>
      <no-data v-if="relateNewsList.length === 0" :show-img="false"/>
      <div v-else class="relate-wrapper">
        <div
          v-for="news in relateNewsList"
          :key="news.id"
          class="relate-item"
          @click="openTo($route.name, {id: news.id})"
        >
          <div class="title text-ellipsis-2 transition-600">{{ news.title }}</div>
          <div class="desc text-ellipsis-2 fs12">{{ news.digest }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NoData from "@/components/Common/NoData";
  import { emeiApi } from "@/service/api/emei";

  export default {
    name: "Article",
    components: { NoData },
    data() {
      return {
        title: '',
        subtitle: '',
        pubTime: '',
        pv: 0,
        content: '',
        relateNewsList: [],
      }
    },
    computed: {
      wechatShareQr() {
        const redirectUrl = encodeURIComponent(process.env.VUE_APP_ARTICLE_REDIRECT_MOBILE + this.$route.query.id)
        return `https://api.pwmqr.com/qrcode/create/?url=${ redirectUrl }`
      }
    },
    mounted() {
      this.fetchArticle()
    },
    methods: {
      async fetchArticle() {
        const { result } = await emeiApi.getArticle({ id: this.$route.query.id })
        const { title, subtitle, publishDate, readAmount, content, typeCode } = result
        this.title = title
        this.subtitle = subtitle
        this.pubTime = publishDate
        this.pv = readAmount
        this.content = content
        if (typeCode) this.fetchRelateNews()
      },
      async fetchRelateNews() {
        const params = { pageNo: 1, pageSize: 7 }
        const { result: { records } } = await emeiApi.getNewsList(params)
        this.relateNewsList = records.filter(item => item.id !== this.$route.query.id).slice(0, 6)
      },
      handleShareToQQ() {
        const shareUrl = `http://connect.qq.com/widget/shareqq/index.html?url=${ encodeURIComponent(location.href) }&sharesource=qzone&title=${ this.title }&summary=${ this.subtitle }`
        window.open(shareUrl)
      }
    }
  }
</script>

<style scoped lang="scss">
  .article-page {
    padding-top: 30px;
    padding-bottom: 70px;
    .left-wrapper {
      width: 885px;
      .header {
        padding-bottom: 25px;
        .title {
          font-size: 30px;
        }
        .info-wrapper {
          margin-top: 25px;
          .info-item {
            color: $font-color-3;
            margin-right: 40px;
          }
        }
        .icon-wrapper {
          i {
            color: #cccccc;
            font-size: 24px;
            margin-left: 12px;
            cursor: pointer;
          }
          .iconicon_share_weibo:hover {
            color: $color-primary;
          }
          .iconicon_share_weixin:hover {
            color: $color-success;
          }
          .iconicon_share_qq:hover {
            color: $color-error;
          }
        }
      }
      .content {
        margin-top: 30px;
        line-height: 30px;
        color: #2F3133;
      }
    }
    .right-wrapper {
      width: 285px;
      height: 100%;
      max-height: 762px;
      border: 1px solid $color-border;
      border-top: none;
      background-color: white;
      .header {
        height: 52px;
        border-top: 4px solid $color-primary;
        padding: 0 16px;
        font-weight: bold;
      }
      .relate-wrapper {
        padding: 20px 16px;
        .relate-item {
          margin-bottom: 26px;
          cursor: pointer;
          .title {
            margin-bottom: 9px;
            line-height: 24px;
          }
          .desc {
            color: $font-color-3;
            line-height: 20px;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          &:hover .title {
            color: $color-primary;
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .article-page img {
    max-width: 885px;
  }
  .wechat-qr-popover {
    min-width: 100px;
    padding: 21px;
    .wechat-qr-wrapper {
      text-align: center;
      .wechat-qr {
        width: 100px;
        height: 100px;
      }
      .wechat-qr-info {
        line-height: 20px;
        color: $font-color-1;
      }
    }
  }
</style>
